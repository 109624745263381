'use client'

import dynamic from 'next/dynamic'
import { useTheme } from '@mui/material/styles'
import { SwipeableDrawer, Container, Grid2 as Grid, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

// Types
import type { DrawerProps } from '@/types/components/organisms/drawer'

// Components
const MenuMolecule = dynamic(() => import('@/components/molecules/menu/mobile'))
// const LogoAtom = dynamic(() => import('@/components/atoms/logo'))

const DrawerOrganism = (props: DrawerProps) => {
	// Props
	const { open, setOpen, items } = props

	// Theme
	const theme = useTheme()

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) return

		setOpen(open)
	}

	return (
		<SwipeableDrawer
			anchor={theme.direction == 'rtl' ? 'left' : 'right'}
			variant="temporary"
			open={open}
			onClose={toggleDrawer(false)}
			onOpen={toggleDrawer(true)}
			sx={{
				'& .MuiPaper-root': {
					width: 1,
					py: 1.25
				}
			}}
		>
			<Container maxWidth="xs" sx={{ height: 1 }}>
				<Grid container direction="row" justifyContent="space-between" sx={{ width: 1, mb: 3 }}>
					<Grid
						size={12}
						height={40}
						sx={{
							'& img': {
								width: 'auto',
								height: 40
							}
						}}
					>
						{/* <LogoAtom /> */}
					</Grid>

					<IconButton color="error" aria-label="close menu" onClick={() => setOpen(false)}>
						<CloseIcon />
					</IconButton>
				</Grid>

				<MenuMolecule setOpen={setOpen} items={items} />
			</Container>
		</SwipeableDrawer>
	)
}

export default DrawerOrganism
